import { Empty, Spin, Table } from 'antd'
import { useNavigate } from 'react-router-dom'
import { InvoicesColumns } from '../components/Tables/Columns/InvoicesColumns'
import { useSelector } from 'react-redux'
import { PaymentsColumns } from '../components/Tables/Columns/PaymentsColumns'
import { ReceiptsColumns } from '../components/Tables/Columns/ReceiptsColumns'
import { RecurringInvoicesColumns } from '../components/Tables/Columns/RecurringInvoicesColumns'
import { ClientsColumns } from '../components/Tables/Columns/ClientsColumns'
import { ServicesColumns } from '../components/Tables/Columns/ServicesColumns'

const HitComponentAlgolia = ({ hit, type }: { hit?: any; type?: string }) => {
    const navigate = useNavigate()

    let navigateTo: string

    if (type === 'invoices') {
        navigateTo = `/invoiceDetails?id=${hit?.id}`
    } else if (type === 'payments') {
        navigateTo = `/paymentDetails?id=${hit?.fid}`
    } else if (type === 'receipts') {
        navigateTo = `/receiptDetails?id=${hit?.id}`
    }

    let columnsToRender: any = {
        clientInfo: {
            width: '34%',
            title: 'Cliente',
        },
        status: {
            width: '8%',
            title: 'Estado',
        },
        amount: {
            width: '17%',
            title: 'Monto',
        },
    }

    const HandleColumns = ({ ...props }) => {
        switch (type) {
            case 'invoices':
                return InvoicesColumns({
                    from: 'typesenseSearch',
                    record: props.record,
                    columnName: props.columnName,
                    navigate: () => navigate(navigateTo),
                })
            case 'payments':
                return PaymentsColumns({
                    from: 'typesenseSearch',
                    record: props.record,
                    columnName: props.columnName,
                    route: navigateTo,
                })
            case 'receipts':
                return ReceiptsColumns({
                    from: 'typesenseSearch',
                    record: props.record,
                    columnName: props.columnName,
                    navigate: () => navigate(navigateTo),
                })
            case 'recurringEvents': //Unused in ComponentSearch.tsx Select
                return RecurringInvoicesColumns({
                    record: props.record,
                    columnName: props.columnName,
                })
            case 'clients':
                return ClientsColumns({ //Unused in ComponentSearch.tsx Select
                    record: props.record,
                    columnName: props.columnName,
                    navigate: () => navigate(navigateTo),
                })
            case 'services':
                return ServicesColumns({ //Unused in ComponentSearch.tsx Select
                    record: props.record,
                    columnName: props.columnName,
                })
            default:
                return InvoicesColumns({
                    from: 'typesenseSearch',
                    record: props.record,
                    columnName: props.columnName,
                    navigate: () => navigate(navigateTo),
                })
        }
    }

    if (type === 'payments') {
        columnsToRender = {
            ...columnsToRender,
            timestamp: {
                width: '17%',
                title: 'Fecha',
            },
            actions: {
                width: '5%',
                title: 'Acciones',
            },
        }
    }

    if (type === 'receipts') {
        columnsToRender = {
            ...columnsToRender,
            timestamp: {
                width: '17%',
                title: 'Fecha',
            },
            actions: {
                width: '5%',
                title: 'Acciones',
            },
        }
    }

    if (type === 'invoices') {
        columnsToRender = {
            ...columnsToRender,
            timestamp: {
                width: '10%',
                title: 'Fecha',
            },
            series: {
                width: '10%',
                title: 'Serie',
            },
            actions: {
                width: '5%',
                title: 'Acciones',
            },
        }
    }

    return (
        <div className="hit-card" style={{ margin: 0 }}>
            <Table
                pagination={false}
                showHeader={false}
                bordered={false}
                columns={Object.keys(columnsToRender).map((key: any) => {
                    return {
                        dataIndex: key,
                        key: key,
                        width: `${columnsToRender[key].width}`,
                        render: (text: any, record: any) => HandleColumns({ record: record, columnName: key }),
                    }
                })}
                dataSource={[{ ...hit }]}
            />
        </div>
    )
}

export const SearchIndexComponent = ({
    indexName,
}: {
    indexName: 'invoices' | 'payments' | 'receipts' | 'recurringEvents' | 'clients' | 'services'
}) => {
    const { searchResult } = useSelector((state: any) => state.data)

    const ToRender = () => {
        if (!searchResult || !searchResult.hits) {
            return (
                <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={`No se encontraron resultados en ${indexName}`}
                />
            )
        }

        if (searchResult.hits.length === 0 || !searchResult.hits) {
            return (
                <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={`No se encontraron resultados en ${indexName}`}
                />
            )
        }

        if (searchResult.hits.length > 0) {
            return searchResult.hits.map((hit: any) => <HitComponentAlgolia hit={hit?.document} type={indexName} />)
        }

        return <Spin />
    }

    return (
        <>
            <ToRender />
        </>
    )
}
